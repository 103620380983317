@font-face {
  font-family: 'Aften Screen';
  src: url('/aften_screen.woff') format('woff');
  font-weight: 400;
  font-variant: normal;
}

* {
  box-sizing: border-box;
}

html {
  background: #000;
  color: #f2f2f2;
  font-family: 'Aften Screen', system, -apple-system, \.SFNSText-Regular, San Francisco, Roboto, Segoe UI, Helvetica Neue, Lucida Grande,
    sans-serif;
}

a {
  color: #f2f2f2;
}

html,
body,
#root {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

article {
  padding: 3vw 5vw 0;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header svg {
  fill: white;
  height: 20px;
}

section {
  margin: 14vw 0 14vw;
}
h1 {
  font-size: 5vw;
  font-weight: 400;
}
p,
section.text {
  max-width: 400px;
  font-size: 16px;
}

.WatchPlaceholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  aspect-ratio: 1/1;
  max-height: 100vh;
  width: 100%;
}
@media (min-width: 800px) {
  .WatchSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5vw;
  }
  .WatchSection.alternate {
    flex-direction: row-reverse;
  }
  .WatchPlaceholder {
    width: 50%;
  }
}

@keyframes showAnnotation {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
}
.annotation {
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 8px;
  font-weight: 300;
  background: #2d4967;
  color: #f0f0f0;
  padding: 2px 10px;
  border-radius: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  opacity: 0;
}
.annotation.visible {
  opacity: 1;
  animation: showAnnotation 1s cubic-bezier(0.19, 1, 0.22, 1) 1s backwards;
}

/* Debug styles */
.Debug {
  outline: 1px dashed rgba(255, 255, 255, 0.33);
  position: relative;
}
.Debug:after {
  content: 'ScrollScene';
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.33);
  color: black;
  font-size: 10px;
  padding: 5px;
}
